// Normalize Styles
@import "node_modules/normalize.css/normalize";

@font-face {
  font-family: 'pitch';
  font-style: "";
  font-weight: regular;
  src: url("../fonts/PitchWeb-Regular.woff") format("woff"); }

@font-face {
  font-family: 'pitch';
  font-style: "";
  font-weight: bold;
  src: url("../fonts/PitchWeb-Bold.woff") format("woff"); }

html {
  font-size: 12px;
  @media screen and(min-width: 1980px) {
    font-size: 16px; } }
body {
  height: 100%;
  width: 100%; }
// Box sizing partial styles:
// Apply a natural box layout model to all elements
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: 'pitch'; }

a {
  color: black; }

.yeogurt-info {
  text-align: center;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto; }

.version {
  font-size: 14px;
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
  position: absolute;
  bottom: 10px;
  right: 10px; }

.logo {
  margin-top: 15px; }

// Styling on warning for users on IE7 or below
.browsehappy {
  text-align: center;
  margin: 0;
  background: #fff8e2;
  color: #000;
  padding: 0.5em 0; }

#navbar {
  z-index: 100;
  position: absolute;
  left: 0;
  width: 7em;
  height: 100%;
  background-color: rgb(255,0,6);
  &.yellow {
    background-color: rgb(235,186,0); }
  //@media screen and (max-width: 768px) and (min-device-pixel-ratio:2)
  //  display: flex
  //  width: 100%
  //  height: 50px
  //  top: 0
  @media screen and (max-width: 768px) {
    display: flex;
    width: 100%;
    height: 50px;
    top: 0; } }

#branding {
  position: absolute;
  transform: rotateZ(-90deg);
  p {
    display: block;
    width: 250px;
    font-size: 1.5em;
    font-weight: bold; }
  @media screen and (min-width: 1980px) {
    left: -4.5rem;
    bottom: 120px;
    p {
      font-size: 2em; } }
  @media screen and (max-width: 1980px) {
    bottom: 120px;
    left: -7rem;
    p {
      font-size: 2em; } }
  @media screen and (max-width: 768px) {
    top: 0;
    left: 0;
    height: 50px;
    margin-left: 25px;
    transform: rotateZ(0deg);
    p {
      margin: 7.5px 0;
      font-weight: regular; } } }

.trigger {
  display: block;
  width: 100%;
  padding-top: 100%;
  @media screen and (max-width: 768px) {
    padding-top: 50px; } }

.menu-icon {
  &, &::before, &::after {
    transition: 0.1s background linear 0.1s, 0.1s top linear 0.1s, 0.1s transform linear;
    position: absolute;
    background: #000;
    margin: 0 auto;
    width: 2em;
    border-bottom: .3em solid #000;
    content: '';
    top: 3em;
    left: 0;
    @media screen and (max-width: 768px) {
      left: auto;
      right: 0;
      top: 25px; } }
  & {
    margin-top: -0.2em;
    margin-left: 2.5em;
    @media screen and (max-width: 768px) {
      margin-right: 2em; } }
  &::before {
    top: -.5em; }
  &::after {
    top: .5em; }
  &::before,
  &::after {
    transform: rotate(0deg); }
  &.active {
    background: 0;
    border-bottom: none;
    &::before {
      transform: rotate(45deg); }
    &::after {
      transform: rotate(-45deg); }
    &::before,
    &::after {
      top: 0; }
    &,
    &::before,
    &::after {
      transition: 0.1s background linear 0.1s, 0.1s top linear, 0.1s transform linear 0.1s; } } }

#background-container {
  z-index: 10;
  display: block;
  .img {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-size: 100% auto;
    background-position: center center;
    opacity: 0;
    transition: opacity .6s ease-in-out;
    &.visible {
      opacity: 1; }
    @media screen and (max-width: 1380px) {
      background-size: cover; } } }
.external-page {
  position: absolute;
  left: -100%;
  height: 100%;
  width: 100%;
  transition: left .4s ease-in-out;
  font-family: 'pitch';
  color: black;
  @media screen and (max-width: 768px) {
    font-size: .7em; }
  &.open {
    left: 0; }
  &#staff {
    background-color: rgba(255,0,6,.9); }
  &#events {
    background-color: rgba(235,186,0,.9); }
  .flex-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 3em;
    overflow-y: scroll;
    @media screen and (min-width: 1380px) {
      padding-left: 5em;
      font-size: 2em; }
    @media screen and (max-width: 1380px) {
      padding-left: 5em;
      font-size: 1.4em;
      justify-content: flex-start; }
    @media screen and (max-width: 768px) {
      padding: 2.5em;
      padding-top: 50px; }
    .head,.foot {
      font-weight: bold;
      width: 100%; }
    .head {
      text-align: right;
      @media screen and (max-width: 768px) {
        text-align: center;
        order: 3;
        font-size: 1.5em;
        margin: 15px 0; } }
    .text p {
      font-size: 1.8em;
      font-weight: regular;
      @media screen and (max-width: 768px) {
        font-size: 1.5em;
        margin-bottom: 2em; } }
    .foot {
      display: flex;
      justify-content: space-between;
      &:nth-child(odd) {
        text-align: right; }
      &:nth-child(even) {
        text-align: left; }
      @media screen and (max-width: 768px) {
        font-size: 1.6em;
        order: 4;
        flex-direction: column;
        //flex-flow: column-reverse
        align-content: flex-start;
        a {
          text-align: center;
          margin: 15px 0; } } } } }
.social {
  display: flex; }
.svg_icon {
  display: block;
  margin-right: 1rem;
  width: 50px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  &.fb {
    background: url(../images/FB_icon.svg); }
  &.ig {
    background: url(../images/IG_icon.svg); } }
